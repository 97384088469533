.columns {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.link {
  color: #00008b;
}

.equal > * {
  flex-basis: 100%;
}

.reverse {
  flex-direction: row-reverse;
}

.section {
  margin: 4rem 2rem;

  @media (min-width: 768px) {
    margin: 6rem 4rem;
  }
}

.content {
  margin: 0 1rem;
}

.centered {
  text-align: center;
}

.image {
  margin: 1rem 0;
}
