.component {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Rambla', sans-serif;
}

.logo {
  max-width: 20rem;
}

.desc {
  padding-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.2;
  text-align: center;
}

.legal {
  margin-top: 2rem;
  font-size: 1.1rem;
  font-weight: bold;
}
