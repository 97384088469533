.component {
  display: flex;
  align-items: flex-start;
  margin: 1rem;
}

.icon {
  flex: 0 0 1.3rem;
  width: 1.3rem;
  margin-right: 1rem;
}

.link {
  color: #00008b;
  white-space: nowrap;
}
