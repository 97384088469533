.footer {
  padding: 2rem;
}

.info {
  margin: 2rem 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  color: #aaa;
  list-style-type: none;
}

.item {
  margin: 0 0.5rem;
  white-space: nowrap;
}

.link {
  color: inherit;

  &:hover {
    color: #485156;
  }
}
