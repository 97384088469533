.component {
  display: flex;
  align-items: flex-start;
  margin: 1rem;
}

.icon {
  flex: 0 0 1.3rem;
  width: 1.3rem;
  margin-right: 1rem;
}

.times {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  list-style-type: none;
}
