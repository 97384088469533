@font-face {
  font-family: 'Rambla';
  font-style: normal;
  font-weight: 400;
  src: url('../public/rambla.woff2') format('woff2');
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.85rem;
  line-height: 1.5;
  color: #485156;
  background: #fffefa;
  //background:
  //        radial-gradient(farthest-side at top left, rgba(173, 252, 249, 1), transparent) fixed,
  //        radial-gradient(farthest-corner at bottom right, rgba(190, 197, 173, 0.8), transparent) fixed;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

h1,
h2,
h3 {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: 'Rambla', sans-serif;
}

img {
  width: 100%;
}

p {
  margin: 1rem 0;
}

ul {
  margin: 1rem 0;
  list-style-type: circle;
}
